var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-6px"},attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-map-signs")])],1)]}}]),model:{value:(_vm.dialogActive),callback:function ($$v) {_vm.dialogActive=$$v},expression:"dialogActive"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Field Mappings for "+_vm._s(_vm.title))])],1),_c('v-card-text',[_c('p',[_vm._v("Below is a representation of the form for this checklist item, with each input being a dropdown to select the Banner data that should be associated with that field.")]),_c('v-row',_vm._l((_vm.fields),function(ref,index){
var label = ref.label;
var input = ref.input;
var path = ref.path;
var lg = ref.lg;
var inputs = ref.inputs;
return _c('v-col',{key:index,attrs:{"lg":lg}},[(input === 'repeatable-block')?_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',[_vm._v("Repeatable Block: "+_vm._s(label))])]),_vm._l((inputs),function(ref,subIndex){
var subLabel = ref.label;
var subLg = ref.lg;
var subPath = ref.path;
return _c('v-col',{key:index + '-' + subIndex,attrs:{"lg":subLg}},[_c('v-select',{attrs:{"value":subPath,"items":_vm.repeatFields,"label":subLabel,"outlined":""},on:{"change":function (path) { return _vm.updateField({ index: index, label: label, subIndex: subIndex, subLabel: subLabel, path: path }); }}})],1)})],2)],1)],1):_c('v-select',{attrs:{"value":path,"items":_vm.dataFields,"label":label,"outlined":""},on:{"change":function (path) { return _vm.fields.splice(index, 1, { label: label, input: input, path: path, lg: lg }); }}})],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogActive = false}}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }