<template>
  <v-dialog v-model="dialogActive" width="800">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon style="margin-top:-6px">
        <v-icon>fal fa-map-signs</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Field Mappings for {{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>Below is a representation of the form for this checklist item, with each input being a dropdown to select the Banner data that should be associated with that field.</p>
        <v-row>
          <v-col v-for="({ label, input, path, lg, inputs }, index) in fields" :key="index" :lg="lg">
            <v-card v-if="input === 'repeatable-block'" elevation="2">
              <v-card-text>
                <v-row>
                  <v-col :cols="12">
                    <span>Repeatable Block: {{ label }}</span>
                  </v-col>
                  <v-col v-for="({ label: subLabel, lg: subLg, path: subPath }, subIndex) in inputs" :key="index + '-' + subIndex" :lg="subLg">
                    <v-select :value="subPath" :items="repeatFields" :label="subLabel" outlined @change="(path) => updateField({ index, label, subIndex, subLabel, path })"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-select v-else :value="path" :items="dataFields" :label="label" outlined @change="(path) => fields.splice(index, 1, { label, input, path, lg })"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" text @click="save">Save</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogActive = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { confirmationModel } from './data-layout'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    fieldMapSource: {
      type: Array,
      default: () => { return [] }
    },
    section: {
      type: String,
      required: true
    },
    revisionId: {
      type: String,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)

    const dataMap = ref(props.fieldMapSource)
    const dataFields = ref([])
    const repeatFields = ref([])
    for (const field in confirmationModel[props.section]) {
      if (Array.isArray(confirmationModel[props.section][field])) {
        for (const subfield in confirmationModel[props.section][field][0]) {
          repeatFields.value.push({ text: field + '.' + subfield, value: props.section + '.' + field + '.' + subfield })
        }
      } else if (typeof (confirmationModel[props.section][field]) === 'object') {
        for (const subfield in confirmationModel[props.section][field]) {
          dataFields.value.push({ text: field + '.' + subfield, value: props.section + '.' + field + '.' + subfield })
        }
      } else {
        dataFields.value.push({ text: field, value: props.section + '.' + field })
      }
    }

    const fields = ref([])
    watch(() => props.revisionId, () => {
      root.$feathers.service('forms/revision').get(props.revisionId).then(({ sections }) => {
        for (const { inputs } of sections) {
          for (const { label, input, lg, inputs: subInputs } of inputs) {
            if (input === 'repeatable-block') {
              let temp = { label, input, lg, inputs: [] }
              for (const { label: subLabel, input: subType, lg: subLg } of subInputs) {
                temp.inputs.push({ label: subLabel, input: subType, lg: subLg, path: '' })
              }
              fields.value.push(temp)
            } else if (input !== 'html-block') {
              fields.value.push({ label, input, lg, path: '' })
            }
          }
        }
        for (let i = 0; i < dataMap.value.length; i++) {
          for (let j = 0; j < fields.value.length; j++) {
            if (dataMap.value[i].label === fields.value[j].label) {
              fields.value.splice(j, 1, { ...fields.value[j], path: dataMap.value[i].path })
              break
            } else if ('inputs' in fields.value[j] && Array.isArray(fields.value[j].inputs)) {
              if (dataMap.value[i].blockLabel === fields.value[j].label) {
                const inputs = fields.value[j].inputs
                for (let k = 0; k < inputs.length; k++) {
                  if (dataMap.value[i].label === inputs[k].label) {
                    inputs.splice(k, 1, { ...inputs[k], path: dataMap.value[i].path })
                    break
                  }
                }
                fields.value.splice(j, 1, { ...fields.value[j], inputs })
                break
              }
            }
          }
        }
      })
    })

    function updateField ({ index, subIndex, path }) {
      let inputs = fields.value[index].inputs
      inputs.splice(subIndex, 1, { ...inputs[subIndex], path })
      fields.value.splice(index, 1, { ...fields.value[index], inputs })
    }

    function save () {
      let fieldMap = []
      for (const { label, path, input, inputs } of fields.value) {
        if (input === 'repeatable-block' && Array.isArray(inputs) && inputs.length > 0) {
          for (const { label: subLabel, path: subPath } of inputs) {
            if (subPath == null || subPath === '') continue
            fieldMap.push({ blockLabel: label, label: subLabel, path: subPath })
          }
        } else if (path == null || path === '') {
          continue
        } else {
          fieldMap.push({ label, path })
        }
      }
      emit('update', { fieldMap })
      dialogActive.value = false
    }

    return {
      dialogActive,
      dataMap,
      dataFields,
      repeatFields,
      fields,
      updateField,
      save
    }
  }
}
</script>
